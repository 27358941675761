import { uuid } from "@grrr/utils";

const FETCH_PARAMS = {
  method: "post",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

/**
 * Checks that an element has a non-empty `name`.
 * @param  {Element} element  the element to check
 * @return {Bool}             true if the element is an input, false if not
 */
const isValidElement = (element) => {
  return element.name;
};

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
const isValidValue = (element) => {
  return !["checkbox", "radio"].includes(element.type) || element.checked;
};

const isCheckbox = (element) => element.type === "checkbox";

/*
const isMultiSelect = element => element.options && element.multiple;
*/

/**
 * Some input names for multiselection needs [] at the end of the name for
 * a normal post request. But with for javascript fetch we need to remove
 * these brackets for the JSON payload.
 */
const removeArrayNotation = (string) => {
  return string.replace("[]", "");
};

/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
const formToJSON = (form) =>
  [].reduce.call(
    form.elements,
    (data, element) => {
      if (isValidElement(element) && isValidValue(element)) {
        /*
         * Some fields allow for more than one value, so we need to check if this
         * is one of those fields and, if so, store the values as an array.
         */
        if (isCheckbox(element)) {
          data[removeArrayNotation(element.name)] = (
            data[removeArrayNotation(element.name)] || []
          ).concat(element.value);
        } else {
          data[element.name] = element.value;
        }
      }
      return data;
    },
    {}
  );

const createAlertElement = (form) => {
  const el = document.createElement("span");
  const alertId = `alert-${uuid()}`;
  el.id = alertId;
  el.classList.add("form-alert");
  el.setAttribute("role", "alert");
  el.setAttribute("aria-hidden", "true");
  form.setAttribute("aria-describedby", alertId);
  form.appendChild(el);
  return el;
};

const Form = (form) => {
  const alertElement = createAlertElement(form);
  const successUrl = form.getAttribute("data-success-url");
  const button = form.querySelector('[type="submit"]');
  const honeypotInput = form.querySelector("input[data-nocomplete]");

  const enableButton = () => {
    button.disabled = false;
  };
  const disableButton = () => {
    button.disabled = true;
  };

  const hideAlert = () => {
    form.removeAttribute("aria-describedby");
    form.removeAttribute("aria-invalid");
    alertElement.setAttribute("aria-hidden", "true");
  };

  const showAlert = (message, type) => {
    if (type === "error") {
      form.setAttribute("aria-invalid", "true");
      form.setAttribute("aria-describedby", alertElement.id);
    }
    alertElement.setAttribute("aria-hidden", "false");
    alertElement.setAttribute("data-type", type);
    alertElement.textContent = message;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    hideAlert();
    disableButton();
    if (honeypotInput) {
      honeypotInput.value = "";
    }
    fetch(form.getAttribute("action"), {
      ...FETCH_PARAMS,
      body: JSON.stringify(formToJSON(form)),
    }).then((response) => {
      if (!response.ok) {
        enableButton();
        return response
          .json()
          .then((result) => {
            if (window.grecaptcha) {
              grecaptcha.reset();
            }
            showAlert(result.message, "error");
          })
          .catch((error) => {
            showAlert(error, "error");
          });
      }
      if (successUrl) {
        window.location.href = successUrl;
        return undefined;
      }
      enableButton();
      return response
        .json()
        .then((result) => {
          showAlert(result, "success");
          form.reset();
        })
        .catch((error) => {
          showAlert(error, "error");
        });
    });
  };

  return {
    init: () => form.addEventListener("submit", handleSubmit),
  };
};

export const enhancer = (form) => {
  const formInstance = Form(form);
  formInstance.init();
};
